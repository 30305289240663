import React from "react"
import { ThemeProvider } from "@material-ui/core"
import { theme } from "../styles/theme"

const Credits = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <div>Hello</div>
      </ThemeProvider>
    </div>
  )
}

export default Credits
